/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}.__variable_d65c78 {--font-inter: '__Inter_d65c78', '__Inter_Fallback_d65c78'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/8d7c84a11f840916-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/419a051b363af584-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/778ce0abc5526d65-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/b17f997f9ae7c671-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/3064ca4f871fda7c-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/ddcfaf3c507de899-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/06aa60d9335bb7b5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/9798597226cbd355-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_28a512';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/24c66e387ee021e3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_28a512';src: local("Arial");ascent-override: 92.98%;descent-override: 24.47%;line-gap-override: 0.00%;size-adjust: 99.78%
}.__className_28a512 {font-family: '__Roboto_28a512', '__Roboto_Fallback_28a512';font-weight: 500;font-style: normal
}.__variable_28a512 {--font-roboto: '__Roboto_28a512', '__Roboto_Fallback_28a512'
}

.theme-zinc {
  --background: 0 0% 100%;
  --foreground: 240 10% 3.9%;

  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;

  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;

  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;

  --primary: 240 5.9% 10%;
  --primary-foreground: 0 0% 98%;

  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;

  --accent: 240 4.8% 95.9%;
  --accent-foreground: 240 5.9% 10%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;

  --ring: 240 5.9% 10%;

  --radius: 0.5rem;
}

.dark .theme-zinc {
  --background: 240 10% 3.9%;
  --foreground: 0 0% 98%;

  --muted: 240 3.7% 15.9%;
  --muted-foreground: 240 5% 64.9%;

  --popover: 240 10% 3.9%;
  --popover-foreground: 0 0% 98%;

  --card: 240 10% 3.9%;
  --card-foreground: 0 0% 98%;

  --border: 240 3.7% 15.9%;
  --input: 240 3.7% 15.9%;

  --primary: 0 0% 98%;
  --primary-foreground: 240 5.9% 10%;

  --secondary: 240 3.7% 15.9%;
  --secondary-foreground: 0 0% 98%;

  --accent: 240 3.7% 15.9%;
  --accent-foreground: 0 0% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 0 0% 98%;

  --ring: 240 4.9% 83.9%;
}

.theme-slate {
  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;

  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;

  --popover: 0 0% 100%;
  --popover-foreground: 222.2 84% 4.9%;

  --card: 0 0% 100%;
  --card-foreground: 222.2 84% 4.9%;

  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;

  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;

  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;

  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 40% 98%;

  --ring: 222.2 84% 4.9%;

  --radius: 0.5rem;
}

.dark .theme-slate {
  --background: 222.2 84% 4.9%;
  --foreground: 210 40% 98%;

  --muted: 217.2 32.6% 17.5%;
  --muted-foreground: 215 20.2% 65.1%;

  --popover: 222.2 84% 4.9%;
  --popover-foreground: 210 40% 98%;

  --card: 222.2 84% 4.9%;
  --card-foreground: 210 40% 98%;

  --border: 217.2 32.6% 17.5%;
  --input: 217.2 32.6% 17.5%;

  --primary: 210 40% 98%;
  --primary-foreground: 222.2 47.4% 11.2%;

  --secondary: 217.2 32.6% 17.5%;
  --secondary-foreground: 210 40% 98%;

  --accent: 217.2 32.6% 17.5%;
  --accent-foreground: 210 40% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 40% 98%;

  --ring: 212.7 26.8% 83.9;
}

.theme-stone {
  --background: 0 0% 100%;
  --foreground: 20 14.3% 4.1%;

  --muted: 60 4.8% 95.9%;
  --muted-foreground: 25 5.3% 44.7%;

  --popover: 0 0% 100%;
  --popover-foreground: 20 14.3% 4.1%;

  --card: 0 0% 100%;
  --card-foreground: 20 14.3% 4.1%;

  --border: 20 5.9% 90%;
  --input: 20 5.9% 90%;

  --primary: 24 9.8% 10%;
  --primary-foreground: 60 9.1% 97.8%;

  --secondary: 60 4.8% 95.9%;
  --secondary-foreground: 24 9.8% 10%;

  --accent: 60 4.8% 95.9%;
  --accent-foreground: 24 9.8% 10%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 60 9.1% 97.8%;

  --ring: 20 14.3% 4.1%;

  --radius: 0.95rem;
}

.dark .theme-stone {
  --background: 20 14.3% 4.1%;
  --foreground: 60 9.1% 97.8%;

  --muted: 12 6.5% 15.1%;
  --muted-foreground: 24 5.4% 63.9%;

  --popover: 20 14.3% 4.1%;
  --popover-foreground: 60 9.1% 97.8%;

  --card: 20 14.3% 4.1%;
  --card-foreground: 60 9.1% 97.8%;

  --border: 12 6.5% 15.1%;
  --input: 12 6.5% 15.1%;

  --primary: 60 9.1% 97.8%;
  --primary-foreground: 24 9.8% 10%;

  --secondary: 12 6.5% 15.1%;
  --secondary-foreground: 60 9.1% 97.8%;

  --accent: 12 6.5% 15.1%;
  --accent-foreground: 60 9.1% 97.8%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 60 9.1% 97.8%;

  --ring: 24 5.7% 82.9%;
}

.theme-gray {
  --background: 0 0% 100%;
  --foreground: 224 71.4% 4.1%;

  --muted: 220 14.3% 95.9%;
  --muted-foreground: 220 8.9% 46.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 224 71.4% 4.1%;

  --card: 0 0% 100%;
  --card-foreground: 224 71.4% 4.1%;

  --border: 220 13% 91%;
  --input: 220 13% 91%;

  --primary: 220.9 39.3% 11%;
  --primary-foreground: 210 20% 98%;

  --secondary: 220 14.3% 95.9%;
  --secondary-foreground: 220.9 39.3% 11%;

  --accent: 220 14.3% 95.9%;
  --accent-foreground: 220.9 39.3% 11%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 20% 98%;

  --ring: 224 71.4% 4.1%;

  --radius: 0.35rem;
}

.dark .theme-gray {
  --background: 224 71.4% 4.1%;
  --foreground: 210 20% 98%;

  --muted: 215 27.9% 16.9%;
  --muted-foreground: 217.9 10.6% 64.9%;

  --popover: 224 71.4% 4.1%;
  --popover-foreground: 210 20% 98%;

  --card: 224 71.4% 4.1%;
  --card-foreground: 210 20% 98%;

  --border: 215 27.9% 16.9%;
  --input: 215 27.9% 16.9%;

  --primary: 210 20% 98%;
  --primary-foreground: 220.9 39.3% 11%;

  --secondary: 215 27.9% 16.9%;
  --secondary-foreground: 210 20% 98%;

  --accent: 215 27.9% 16.9%;
  --accent-foreground: 210 20% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 20% 98%;

  --ring: 216 12.2% 83.9%;
}

.theme-neutral {
  --background: 0 0% 100%;
  --foreground: 0 0% 3.9%;

  --muted: 0 0% 96.1%;
  --muted-foreground: 0 0% 45.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 0 0% 3.9%;

  --card: 0 0% 100%;
  --card-foreground: 0 0% 3.9%;

  --border: 0 0% 89.8%;
  --input: 0 0% 89.8%;

  --primary: 0 0% 9%;
  --primary-foreground: 0 0% 98%;

  --secondary: 0 0% 96.1%;
  --secondary-foreground: 0 0% 9%;

  --accent: 0 0% 96.1%;
  --accent-foreground: 0 0% 9%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;

  --ring: 0 0% 3.9%;

  --radius: ;
}

.dark .theme-neutral {
  --background: 0 0% 3.9%;
  --foreground: 0 0% 98%;

  --muted: 0 0% 14.9%;
  --muted-foreground: 0 0% 63.9%;

  --popover: 0 0% 3.9%;
  --popover-foreground: 0 0% 98%;

  --card: 0 0% 3.9%;
  --card-foreground: 0 0% 98%;

  --border: 0 0% 14.9%;
  --input: 0 0% 14.9%;

  --primary: 0 0% 98%;
  --primary-foreground: 0 0% 9%;

  --secondary: 0 0% 14.9%;
  --secondary-foreground: 0 0% 98%;

  --accent: 0 0% 14.9%;
  --accent-foreground: 0 0% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 0 0% 98%;

  --ring: 0 0% 83.1%;
}

.theme-red {
  --background: 0 0% 100%;
  --foreground: 0 0% 3.9%;

  --muted: 0 0% 96.1%;
  --muted-foreground: 0 0% 45.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 0 0% 3.9%;

  --card: 0 0% 100%;
  --card-foreground: 0 0% 3.9%;

  --border: 0 0% 89.8%;
  --input: 0 0% 89.8%;

  --primary: 0 72.2% 50.6%;
  --primary-foreground: 0 85.7% 97.3%;

  --secondary: 0 0% 96.1%;
  --secondary-foreground: 0 0% 9%;

  --accent: 0 0% 96.1%;
  --accent-foreground: 0 0% 9%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;

  --ring: 0 72.2% 50.6%;

  --radius: 0.4rem;
}

.dark .theme-red {
  --background: 0 0% 3.9%;
  --foreground: 0 0% 98%;

  --muted: 0 0% 14.9%;
  --muted-foreground: 0 0% 63.9%;

  --popover: 0 0% 3.9%;
  --popover-foreground: 0 0% 98%;

  --card: 0 0% 3.9%;
  --card-foreground: 0 0% 98%;

  --border: 0 0% 14.9%;
  --input: 0 0% 14.9%;

  --primary: 0 72.2% 50.6%;
  --primary-foreground: 0 85.7% 97.3%;

  --secondary: 0 0% 14.9%;
  --secondary-foreground: 0 0% 98%;

  --accent: 0 0% 14.9%;
  --accent-foreground: 0 0% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 0 0% 98%;

  --ring: 0 72.2% 50.6%;
}

.theme-rose {
  --background: 0 0% 100%;
  --foreground: 240 10% 3.9%;

  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;

  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;

  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;

  --primary: 346.8 77.2% 49.8%;
  --primary-foreground: 355.7 100% 97.3%;

  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;

  --accent: 240 4.8% 95.9%;
  --accent-foreground: 240 5.9% 10%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;

  --ring: 346.8 77.2% 49.8%;

  --radius: 0.5rem;
}

.dark .theme-rose {
  --background: 20 14.3% 4.1%;
  --foreground: 0 0% 95%;

  --muted: 0 0% 15%;
  --muted-foreground: 240 5% 64.9%;

  --popover: 0 0% 9%;
  --popover-foreground: 0 0% 95%;

  --card: 24 9.8% 10%;
  --card-foreground: 0 0% 95%;

  --border: 240 3.7% 15.9%;
  --input: 240 3.7% 15.9%;

  --primary: 346.8 77.2% 49.8%;
  --primary-foreground: 355.7 100% 97.3%;

  --secondary: 240 3.7% 15.9%;
  --secondary-foreground: 0 0% 98%;

  --accent: 12 6.5% 15.1%;
  --accent-foreground: 0 0% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 0 85.7% 97.3%;

  --ring: 346.8 77.2% 49.8%;
}

.theme-orange {
  --background: 0 0% 100%;
  --foreground: 20 14.3% 4.1%;

  --muted: 60 4.8% 95.9%;
  --muted-foreground: 25 5.3% 44.7%;

  --popover: 0 0% 100%;
  --popover-foreground: 20 14.3% 4.1%;

  --card: 0 0% 100%;
  --card-foreground: 20 14.3% 4.1%;

  --border: 20 5.9% 90%;
  --input: 20 5.9% 90%;

  --primary: 24.6 95% 53.1%;
  --primary-foreground: 60 9.1% 97.8%;

  --secondary: 60 4.8% 95.9%;
  --secondary-foreground: 24 9.8% 10%;

  --accent: 60 4.8% 95.9%;
  --accent-foreground: 24 9.8% 10%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 60 9.1% 97.8%;

  --ring: 24.6 95% 53.1%;

  --radius: 0.95rem;
}

.dark .theme-orange {
  --background: 20 14.3% 4.1%;
  --foreground: 60 9.1% 97.8%;

  --muted: 12 6.5% 15.1%;
  --muted-foreground: 24 5.4% 63.9%;

  --popover: 20 14.3% 4.1%;
  --popover-foreground: 60 9.1% 97.8%;

  --card: 20 14.3% 4.1%;
  --card-foreground: 60 9.1% 97.8%;

  --border: 12 6.5% 15.1%;
  --input: 12 6.5% 15.1%;

  --primary: 20.5 90.2% 48.2%;
  --primary-foreground: 60 9.1% 97.8%;

  --secondary: 12 6.5% 15.1%;
  --secondary-foreground: 60 9.1% 97.8%;

  --accent: 12 6.5% 15.1%;
  --accent-foreground: 60 9.1% 97.8%;

  --destructive: 0 72.2% 50.6%;
  --destructive-foreground: 60 9.1% 97.8%;

  --ring: 20.5 90.2% 48.2%;
}

.theme-green {
  --background: 0 0% 100%;
  --foreground: 240 10% 3.9%;

  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;

  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;

  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;

  --primary: 142.1 76.2% 36.3%;
  --primary-foreground: 355.7 100% 97.3%;

  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;

  --accent: 240 4.8% 95.9%;
  --accent-foreground: 240 5.9% 10%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;

  --ring: 142.1 76.2% 36.3%;

  --radius: ;
}

.dark .theme-green {
  --background: 20 14.3% 4.1%;
  --foreground: 0 0% 95%;

  --muted: 0 0% 15%;
  --muted-foreground: 240 5% 64.9%;

  --popover: 0 0% 9%;
  --popover-foreground: 0 0% 95%;

  --card: 24 9.8% 10%;
  --card-foreground: 0 0% 95%;

  --border: 240 3.7% 15.9%;
  --input: 240 3.7% 15.9%;

  --primary: 142.1 70.6% 45.3%;
  --primary-foreground: 144.9 80.4% 10%;

  --secondary: 240 3.7% 15.9%;
  --secondary-foreground: 0 0% 98%;

  --accent: 12 6.5% 15.1%;
  --accent-foreground: 0 0% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 0 85.7% 97.3%;

  --ring: 142.4 71.8% 29.2%;
}

.theme-blue {
  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;

  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;

  --popover: 0 0% 100%;
  --popover-foreground: 222.2 84% 4.9%;

  --card: 0 0% 100%;
  --card-foreground: 222.2 84% 4.9%;

  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;

  --primary: 221.2 83.2% 53.3%;
  --primary-foreground: 210 40% 98%;

  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;

  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 40% 98%;

  --ring: 221.2 83.2% 53.3%;

  --radius: ;
}

.dark .theme-blue {
  --background: 222.2 84% 4.9%;
  --foreground: 210 40% 98%;

  --muted: 217.2 32.6% 17.5%;
  --muted-foreground: 215 20.2% 65.1%;

  --popover: 222.2 84% 4.9%;
  --popover-foreground: 210 40% 98%;

  --card: 222.2 84% 4.9%;
  --card-foreground: 210 40% 98%;

  --border: 217.2 32.6% 17.5%;
  --input: 217.2 32.6% 17.5%;

  --primary: 217.2 91.2% 59.8%;
  --primary-foreground: 222.2 47.4% 11.2%;

  --secondary: 217.2 32.6% 17.5%;
  --secondary-foreground: 210 40% 98%;

  --accent: 217.2 32.6% 17.5%;
  --accent-foreground: 210 40% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 40% 98%;

  --ring: 224.3 76.3% 48%;
}

.theme-yellow {
  --background: 0 0% 100%;
  --foreground: 20 14.3% 4.1%;

  --muted: 60 4.8% 95.9%;
  --muted-foreground: 25 5.3% 44.7%;

  --popover: 0 0% 100%;
  --popover-foreground: 20 14.3% 4.1%;

  --card: 0 0% 100%;
  --card-foreground: 20 14.3% 4.1%;

  --border: 20 5.9% 90%;
  --input: 20 5.9% 90%;

  --primary: 47.9 95.8% 53.1%;
  --primary-foreground: 26 83.3% 14.1%;

  --secondary: 60 4.8% 95.9%;
  --secondary-foreground: 24 9.8% 10%;

  --accent: 60 4.8% 95.9%;
  --accent-foreground: 24 9.8% 10%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 60 9.1% 97.8%;

  --ring: 20 14.3% 4.1%;

  --radius: 0.95rem;
}

.dark .theme-yellow {
  --background: 20 14.3% 4.1%;
  --foreground: 60 9.1% 97.8%;

  --muted: 12 6.5% 15.1%;
  --muted-foreground: 24 5.4% 63.9%;

  --popover: 20 14.3% 4.1%;
  --popover-foreground: 60 9.1% 97.8%;

  --card: 20 14.3% 4.1%;
  --card-foreground: 60 9.1% 97.8%;

  --border: 12 6.5% 15.1%;
  --input: 12 6.5% 15.1%;

  --primary: 47.9 95.8% 53.1%;
  --primary-foreground: 26 83.3% 14.1%;

  --secondary: 12 6.5% 15.1%;
  --secondary-foreground: 60 9.1% 97.8%;

  --accent: 12 6.5% 15.1%;
  --accent-foreground: 60 9.1% 97.8%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 60 9.1% 97.8%;

  --ring: 35.5 91.7% 32.9%;
}

.theme-violet {
  --background: 0 0% 100%;
  --foreground: 224 71.4% 4.1%;

  --muted: 220 14.3% 95.9%;
  --muted-foreground: 220 8.9% 46.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 224 71.4% 4.1%;

  --card: 0 0% 100%;
  --card-foreground: 224 71.4% 4.1%;

  --border: 220 13% 91%;
  --input: 220 13% 91%;

  --primary: 262.1 83.3% 57.8%;
  --primary-foreground: 210 20% 98%;

  --secondary: 220 14.3% 95.9%;
  --secondary-foreground: 220.9 39.3% 11%;

  --accent: 220 14.3% 95.9%;
  --accent-foreground: 220.9 39.3% 11%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 20% 98%;

  --ring: 262.1 83.3% 57.8%;

  --radius: ;
}

.dark .theme-violet {
  --background: 224 71.4% 4.1%;
  --foreground: 210 20% 98%;

  --muted: 215 27.9% 16.9%;
  --muted-foreground: 217.9 10.6% 64.9%;

  --popover: 224 71.4% 4.1%;
  --popover-foreground: 210 20% 98%;

  --card: 224 71.4% 4.1%;
  --card-foreground: 210 20% 98%;

  --border: 215 27.9% 16.9%;
  --input: 215 27.9% 16.9%;

  --primary: 263.4 70% 50.4%;
  --primary-foreground: 210 20% 98%;

  --secondary: 215 27.9% 16.9%;
  --secondary-foreground: 210 20% 98%;

  --accent: 215 27.9% 16.9%;
  --accent-foreground: 210 20% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 20% 98%;

  --ring: 263.4 70% 50.4%;
}

